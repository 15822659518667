import FooterBottom from 'Footer/FooterBottom';
import React from 'react';
import { FooterDisclosure } from 'Footer/FooterDisclosure';
import styles from './FreeWriteFooter.module.scss';

const FreeWriteFooter = (): JSX.Element => (
  <>
    <div
      className={styles['outer-container']}
      data-testid="freewrite-footer"
      id="footer-content"
    >
      <div className={styles['content-container']}>
        <div className={styles.row}>
          <div className={styles.column}>
            <h2>Document Categories</h2>
            <ul>
              <li>
                <a href="/tax-forms">Tax</a>
              </li>
              <li>
                <a href="/legal-forms">Legal Forms</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h2>About FormSwift</h2>
            <ul>
              <li>
                <a href="/">Home Page</a>
              </li>
              <li>
                <a href="/faq.php">FAQ</a>
              </li>
              <li>
                <a href="/contact.php">Contact Us</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a
                  href="#manage-cookies"
                  onClick={() => {
                    if (window.dropbox) {
                      window.dropbox.privacyConsent.openOptionsDialog();
                    }
                  }}
                >
                  Do Not Sell or Share my Personal Information
                </a>
              </li>
              <li>
                <a
                  href="#manage-cookies"
                  onClick={() => {
                    if (window.dropbox) {
                      window.dropbox.privacyConsent.openOptionsDialog();
                    }
                  }}
                >
                  Cookies & CCPA preferences
                </a>
              </li>
              <li>
                <a href="/terms.php">Terms of Service</a>
              </li>
              <li>
                <a href="/copyright-policy">Copyright Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <FooterDisclosure />
      </div>
    </div>
    <FooterBottom />
  </>
);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreeWriteFooter;
