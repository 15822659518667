import React from 'react';
import { RootState } from 'store/RootState';
import { useSelector } from 'react-redux';
import { MEMBERS_AREA_TABS } from 'layouts/MembersAreaLayout/MembersAreaLayoutTabs';
import { RotatingNotificationBanner } from 'layouts/Banners/RotatingNotificationBanner';
import { MembersAreaReactivationBanner } from 'layouts/MembersAreaLayout/MembersAreaBanners/MembersAreaReactivationBanner';
import {
  NUM_AUTO_CREATED_DOCUMENTS_COOKIE,
  useIsAutocreateEligible,
} from 'MyDocuments/AutocreateModal';
import { AutocreateBanner } from 'layouts/Banners/AutocreateBanner';
import Cookies from 'js-cookie';

export interface MembersAreaBannersProps {
  activeKey: keyof typeof MEMBERS_AREA_TABS;
}

const AutocreateOrRotatingBanner = (): JSX.Element | null => {
  const {
    eligibleDocumentNames,
    isAutocreateEligible,
  } = useIsAutocreateEligible();
  const numAutocreatedDocuments = Cookies.get(
    NUM_AUTO_CREATED_DOCUMENTS_COOKIE
  );
  const hasAutocreated =
    numAutocreatedDocuments && Number(numAutocreatedDocuments) > 0;

  return isAutocreateEligible && !hasAutocreated ? (
    <AutocreateBanner eligibleDocumentNames={eligibleDocumentNames} />
  ) : (
    <RotatingNotificationBanner />
  );
};

const MembersAreaBanners = ({
  activeKey,
}: MembersAreaBannersProps): JSX.Element => {
  const isCancelled = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, regex/invalid
    ({ UserHandler }: RootState) => UserHandler.userInfo.isCancelled
  );
  const page = MEMBERS_AREA_TABS[activeKey]?.title;

  return (
    <>
      {isCancelled && <MembersAreaReactivationBanner page={page} />}
      {activeKey === 'my-documents' && <AutocreateOrRotatingBanner />}
    </>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default MembersAreaBanners;
