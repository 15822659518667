import React, { useState, useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { ThemeProvider } from '@dropbox/dig-foundations';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronUpLine, ChevronDownLine } from '@dropbox/dig-icons/assets';
import { DefaultLayout } from 'layouts';
import type { DefaultLayoutProps as DefaultLayoutPropsType } from 'layouts/DefaultLayout';
import filterPropsWithPropTypes from 'layouts/filterPropsWithPropTypes';
import DefaultLayoutProps from 'layouts/DefaultLayout/DefaultLayoutProps';
import SiteWrapper from 'layouts/SiteWrapper';
import FreeWriteFooter from 'layouts/FreeWriteLayout/FreeWriteFooter';
import Header from 'Header';
import { SnapEngageChat } from 'Chat/SnapEngageChat';
import styles from './StaticLayout.module.scss';

export const StaticLayout = (props: DefaultLayoutPropsType = {}) => {
  const { children, hideAccount, loggedIn } = props;

  const [isFooterShown, setIsFooterShown] = useState(false);
  const [decision] = useDecision('mobile_funnel_optimization');
  const isMobileFunnelOptimizationOn = decision.variationKey === 'on';

  const toggleFooter = () => setIsFooterShown((prevValue) => !prevValue);

  useEffect(() => {
    if (isFooterShown) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [isFooterShown]);

  if (isMobileFunnelOptimizationOn) {
    return (
      <ThemeProvider
        _dangerouslyIncludeAdditionalClassName="dig-theme--FormSwift"
        mode="bright"
      >
        {({ getThemeProps }) => (
          <div {...getThemeProps()}>
            <SiteWrapper {...props}>
              <Header hideAccount={hideAccount} />
              <main
                className={styles['static-layout-main-container']}
                data-testid="static-layout"
              >
                {loggedIn && <SnapEngageChat />}
                {children}
              </main>
              {loggedIn && <SnapEngageChat />}
              <IconButton
                data-testid="toggle-footer-button"
                inverse={isFooterShown}
                onClick={toggleFooter}
                variant="borderless"
              >
                {isFooterShown ? (
                  <UIIcon aria-label="toggle footer" src={ChevronDownLine} />
                ) : (
                  <UIIcon aria-label="toggle footer" src={ChevronUpLine} />
                )}
              </IconButton>
              {isFooterShown ? <FreeWriteFooter /> : null}
            </SiteWrapper>
          </div>
        )}
      </ThemeProvider>
    );
  }

  return <DefaultLayout {...props} />;
};

StaticLayout.getLayoutProps = (props: Partial<Record<string, unknown>>) =>
  filterPropsWithPropTypes(props, DefaultLayoutProps);
