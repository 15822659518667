import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUser, useBuilderType } from 'hooks';
import useTrack from 'lib/tracking/useTrack';
import { useRouter } from 'next/router';
import FreemiumMenuIcon from './FreemiumMenuIcon';
import FreemiumMenuDataFragment from '../gql/FreemiumMenuDataFragment';
import FreemiumMenuHeaderText from './FreemiumMenuHeaderText';
import FreemiumMenuHeaderSubText from './FreemiumMenuHeaderSubText';
import FreemiumMenuCta from './FreemiumMenuCta';
import styles from './index.module.scss';

const FreemiumMenu = ({ userEmail }) => {
  const { isFreewrite, isVisualStatic } = useBuilderType();
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const track = useTrack();
  const getHelpUrl = () => {
    if (isFreewrite) {
      return 'https://help.formswift.com/knowledge/pdf-editor-help';
    }
    if (isVisualStatic) {
      return 'https://help.formswift.com/knowledge/tax-documents-e-file-help';
    }
    return 'https://help.formswift.com/knowledge';
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    if (isVisualStatic) {
      const { documentType } = router?.query || {};
      const parsedDocumentType = documentType || '';
      const isMemberPage = router.pathname.endsWith('/member');
      void track({
        action: `${parsedDocumentType} | Visual Static Builder${
          isMemberPage ? ' [Member]' : ''
        }`,
        category: 'Dismiss | Account Dropdown',
      });
    }
    setIsOpen(false);
  };

  const { data } = useUser(
    'FreemiumMenuDataFragment',
    FreemiumMenuDataFragment
  );

  const user = data?.user;
  // The following should fix itself after useUser is moved to typescript
  // eslint-disable @typescript-eslint/no-unsafe-member-access
  const credits = user?.credits;
  const isCurrentSubscriber = user?.isCurrentSubscriber;
  const planId = user?.planId;
  // eslint-enable @typescript-eslint/no-unsafe-member-access

  return (
    <div
      className="freemium-menu-wrapper"
      data-testid="freemium-menu-wrapper"
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <FreemiumMenuIcon
        credits={credits}
        isCurrentSubscriber={isCurrentSubscriber}
      />
      <p className="freemium-menu-email">
        {decodeURIComponent(userEmail)}
        &#x2304;
      </p>
      {isOpen && (
        <div className="freemium-menu-dropdown-wrapper">
          <div className={styles['freemium-menu-header-wrapper']}>
            <div className={styles['freemium-menu-arrow']} />
            <FreemiumMenuHeaderText
              credits={credits}
              isCurrentSubscriber={isCurrentSubscriber}
            />
            <FreemiumMenuHeaderSubText planId={planId} />
            <FreemiumMenuCta isCurrentSubscriber={isCurrentSubscriber} />
          </div>
          <a href="/my-documents">My Documents</a>
          <a href="/my-account">Settings</a>
          <a href={getHelpUrl()}>Help</a>
          <a href="/_api/logout.php">Log Out</a>
        </div>
      )}
    </div>
  );
};

FreemiumMenu.propTypes = {
  userEmail: PropTypes.string.isRequired,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenu;
