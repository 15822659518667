import React from 'react';
import { NextPage, NextPageContext } from 'next';
import { DefaultLayout, LayoutType } from 'layouts';
import { DefaultLayoutProps } from 'layouts/DefaultLayout';
import {
  SSRDeviceContextProps,
  SSRDeviceContext,
} from 'Utils/SSRDeviceContext';

const withLayout = <
  P extends object & SSRDeviceContextProps,
  LayoutProps extends object = DefaultLayoutProps,
  IP = P
>(
  WrappedComponent: NextPage<P, IP>,
  Layout: LayoutType<LayoutProps> = (DefaultLayout as unknown) as LayoutType<LayoutProps>
): NextPage<P, IP> => {
  const WrappedComponentWithLayout = (props: P) => {
    const layoutProps = Layout.getLayoutProps(props);
    const { device } = props;

    return (
      <SSRDeviceContext.Provider
        value={{
          device,
        }}
      >
        <Layout {...layoutProps}>
          <WrappedComponent {...props} />
        </Layout>
      </SSRDeviceContext.Provider>
    );
  };

  WrappedComponentWithLayout.getInitialProps = WrappedComponent.getInitialProps
    ? (ctx: NextPageContext) => {
        return (WrappedComponent.getInitialProps as (
          context: NextPageContext
        ) => IP | Promise<IP>)(ctx);
      }
    : undefined;

  return WrappedComponentWithLayout;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default withLayout;
