import { SnapEngageHandler } from 'lib/chat/SnapEngageHandler';
import { ChatData } from 'lib/chat/types';

/**
 * The ChatClient initializes SnapEngage and provides an interface for
 * controlling the chat experience on the page, such as toggling chat on/off
 */
export class ChatClient {
  snapEngageHandler?: SnapEngageHandler;

  chatData?: ChatData;

  iframe?: HTMLIFrameElement;

  chatIsEnabled = false;

  loadChatData = (chatData: ChatData): void => {
    this.chatData = chatData;
    this.loadSnapEngageHandlerIfReady();
  };

  loadIframe = (iframe: HTMLIFrameElement): void => {
    this.iframe = iframe;
    this.loadSnapEngageHandlerIfReady();
  };

  enableChat = (): void => {
    this.chatIsEnabled = true;
    this.showChatIfReady();
  };

  // TODO: Report metric/error here
  reportError = (err: Error): void => {};

  loadSnapEngageHandlerIfReady = (): void => {
    if (!this.snapEngageHandler && this.iframe && this.chatData) {
      this.snapEngageHandler = new SnapEngageHandler({
        chatData: this.chatData,
        iframe: this.iframe,
      });
      if (this.chatIsEnabled) {
        this.showChatIfReady();
      }
    }
  };

  showChatIfReady = (): void => {
    if (this.snapEngageHandler) {
      this.snapEngageHandler.showChatWindow();
    }
  };
}

export const chatClient = new ChatClient();
