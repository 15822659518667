import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Text } from '@dropbox/dig-components/dist/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  FacebookExternalLogo,
  TwitterExternalLogo,
  YoutubeExternalLogo,
} from '@dropbox/dig-icons/assets';
import styles from './RedesignedFooter.module.scss';

export const aboutSectionLinks = [
  {
    href: '/',
    text: 'Home page',
  },
  {
    href: '/faq.php',
    text: 'FAQ',
  },
  {
    href: '/contact.php',
    text: 'Contact us',
  },
  {
    href: '/privacy',
    text: 'Privacy policy',
  },
  {
    href: '#manage-cookies',
    text: 'Cookies & CCPA preferences',
  },
  {
    href: '/terms',
    text: 'Terms of service',
  },
];

export const popularDocumentLinks = [
  {
    href: '/personal-financial-statement',
    text: 'Personal Financial Statement',
  },
  {
    href: '/invoice',
    text: 'Invoice',
  },
  {
    href: '/release-of-liability',
    text: 'Release of Liability',
  },
  {
    href: '/job-application',
    text: 'Job Application',
  },
  {
    href: '/employment-contract',
    text: 'Employment Contract',
  },
  {
    href: '/non-disclosure-agreement',
    text: 'Non-disclosure Agreement',
  },
  {
    href: '/affidavit',
    text: 'Affidavit',
  },
  {
    href: '/bill-of-sale',
    text: 'Bill of Sale',
  },
  {
    href: '/employee-handbook',
    text: 'Employee Handbook',
  },
  {
    href: '/last-will-and-testament',
    text: 'Last Will and Testament',
  },
  {
    href: '/w2',
    text: 'W2',
  },
  {
    href: '/w9',
    text: 'W9',
  },
  {
    href: '/1099-misc',
    text: '1099-MISC',
  },
  {
    href: '/lease-agreement',
    text: 'Lease Agreement',
  },
];

export const socialMediaLinks = [
  {
    href: 'https://twitter.com/Dropbox',
    logo: TwitterExternalLogo,
    social: 'twitter',
  },
  {
    href: 'https://facebook.com/Dropbox',
    logo: FacebookExternalLogo,
    social: 'facebook',
  },
  {
    href: 'https://youtube.com/user/Dropbox',
    logo: YoutubeExternalLogo,
    social: 'youtube',
  },
];

export const RedesignedFooter = (): JSX.Element => (
  <footer
    className={styles['redesigned-footer']}
    data-testid="redesigned-footer"
  >
    <Container className={styles['footer-container']} fluid>
      <Row className={styles.sections}>
        <Col
          className={styles['about-section']}
          data-testid="about-section"
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <div className={styles['section-header']}>
            <Text inverse isBold size="large" variant="label">
              About Formswift
            </Text>
          </div>
          <div className={styles['about-section-links']}>
            {aboutSectionLinks.map(({ href, text }) => (
              <Text
                key={`footerLink-${text}`}
                inverse
                size="medium"
                tagName="p"
                variant="label"
              >
                <Link
                  hasNoUnderline
                  href={href}
                  inverse
                  onClick={() => {
                    if (
                      text === 'Cookies & CCPA preferences' &&
                      window.dropbox
                    ) {
                      window.dropbox.privacyConsent.openOptionsDialog();
                    }
                  }}
                >
                  {text}
                </Link>
              </Text>
            ))}
          </div>
        </Col>
        <Col
          data-testid="popular-documents-section"
          lg={9}
          md={8}
          sm={6}
          xs={12}
        >
          <div className={styles['section-header']}>
            <Text inverse isBold size="large" variant="label">
              Most popular documents
            </Text>
          </div>
          <div className={styles['popular-documents-grid']}>
            {popularDocumentLinks.map(({ href, text }) => (
              <Text
                key={`footerLink-${text}`}
                inverse
                size="medium"
                tagName="p"
                variant="label"
              >
                <Link hasNoUnderline href={href} inverse>
                  {text}
                </Link>
              </Text>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className={styles['social-media']}>
            {socialMediaLinks.map(({ href, logo, social }) => (
              <Text key={`footerLink-${social}`} inverse>
                <Link
                  data-testid={`${social}-link`}
                  hasNoUnderline
                  href={href}
                  inverse
                >
                  <UIIcon size="large" src={logo} />
                </Link>
              </Text>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Text className={styles.legal} inverse size="medium" variant="label">
            Formswift is not a law firm and does not provide legal advice or
            representation. Our documents are not a substitute for the advice of
            an attorney. Communications between you and Formswift are governed
            by our{' '}
            <Link className="dropbox" href="/privacy">
              Privacy policy
            </Link>{' '}
            but are not protected by the attorney-client privilege or as work
            product. We do not provide advice, opinions, or recommendations
            about any individual&apos;s legal rights, options, strategies, or
            the selection of forms. Your use of this website and our forms is
            governed by our <Link href="/terms">Terms of service</Link>.
          </Text>
        </Col>
      </Row>
    </Container>
  </footer>
);
