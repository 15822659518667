import React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { useGetUserSubscriptionDetailsForLayoutQuery } from 'lib/graphql/Layouts/__generated__/LayoutsQueries.generated';
import ComponentRandomizer from './ComponentRandomizer';
import { checkBannerHidden } from './Utils';
import { UpdatedFormsBanner } from './UpdatedFormsBanner';
import { taxFormBannerConfig, TaxFormBanner } from './TaxFormBanner';
import { MultipleSeatsInviteTeammateBanner } from './MultipleSeatsInviteTeammateBanner';

// Prioritized greatest number to lowest [10..., 3, 2, 1]
export const bannerPriorities: {
  MULTIPLE_SEATS: number;
  TAX_FORMS: number;
  UPDATED_FORMS: number;
} = {
  MULTIPLE_SEATS: 3,
  TAX_FORMS: 2,
  UPDATED_FORMS: 1,
};

export const RotatingNotificationBanner = (): JSX.Element => {
  const isUpdatedFormsBannerVisible = !checkBannerHidden('updatedFormsBanner');
  const isTaxFormsBannerVisible =
    taxFormBannerConfig.SHOW_BANNER &&
    !checkBannerHidden(taxFormBannerConfig.BANNER_ID);

  const { data } = useGetUserSubscriptionDetailsForLayoutQuery();
  const [decision] = useDecision('multiple_seats');
  const isCurrentSubscriber = data?.user?.isCurrentSubscriber;
  const hasSeatsAvailable =
    (data?.user?.organization?.organizationMemberDetails
      ?.numberOfSeatsRemaining || 0) >= 0;

  const showInviteTeammateBanner =
    decision.variationKey === 'multiple_seats_active' &&
    !!isCurrentSubscriber &&
    hasSeatsAvailable &&
    !checkBannerHidden('multipleSeatsBanner');

  const banners = [
    {
      component: <MultipleSeatsInviteTeammateBanner />,
      conditional: () => showInviteTeammateBanner,
      priority: bannerPriorities.MULTIPLE_SEATS,
    },
    {
      component: <UpdatedFormsBanner />,
      conditional: () => isUpdatedFormsBannerVisible,
      priority: bannerPriorities.UPDATED_FORMS,
    },
    {
      component: <TaxFormBanner />,
      conditional: () => isTaxFormsBannerVisible,
      priority: bannerPriorities.TAX_FORMS,
    },
  ];

  return <ComponentRandomizer componentsToRandomize={banners} />;
};
